import { Component, OnInit } from '@angular/core';
import { AuthService, HijiBundle } from 'common';

@Component({
  selector: 'hiji-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
})
export class SearchCardComponent implements OnInit {
  manualHierarchicalManagement: boolean = false;
  constructor(private authService: AuthService) {
    this.manualHierarchicalManagement = (<HijiBundle>this.authService.userBundle.data).modules.manualHierarchicalManagement;
  }

  ngOnInit(): void {}
}
