<div class="container-fluid h-100 d-flex flex-column fit-height pb-4">
  <div class="row" *ngIf="loading">
    <div class="col">
      <i class="fa fa-spinner spinning"></i>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="row">
      <div class="row">
        <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 my-fs-4 mt-4">
          <b>{{ 'search-user.Rechercher un collaborateur :' | translate }}</b>
        </div>
      </div>

      <div class="row">
        <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 my-fs-2 my-fs-xxl-3 mt-3">
          <input class="form-control" name="searchUsers" (keyup)="searchUser($event)" type="text" placeholder="{{ 'search-user.Rechercher' | translate }}" />
        </div>
      </div>
    </div>

    <div class="row overflow-auto">
      <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 my-fs-2 my-fs-xxl-3 mt-3">
        <div class="rounded-3 bg-white p-2 mt-2" *ngFor="let user of users">
          <a class="text-body" (click)="userClick(user)">
            <div class="my-2 pointer">
              <div class="row g-2 align-items-center">
                <div class="col-auto">
                  <hiji-user-avatar
                    style="width: 50px; height: 50px"
                    [idUser]="user.idUser"
                    [avatarExtension]="user.avatarExtension"
                    [defaultBlueAvatar]="true"
                  ></hiji-user-avatar>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-auto">{{ user.firstName }} {{ user.lastName }}</div>
                    <div *ngIf="displayFunctionFront">{{ user.functionName }}</div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
