<div class="row" *ngFor="let campaign of data.campaigns">
  <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
    <div class="bg-white border rounded-3 p-2">
      <div class="row mt-1">
        <div class="col-12 fw-bold text-center">{{ campaign.name | ms }}</div>
      </div>
      <ng-container *ngFor="let repository of campaign.repositories; let index = index">
        <div class="row mt-3">
          <div class="col-auto">
            {{ repository.name | ms }}
          </div>
        </div>
        <div class="border border-1 border-light-30p"></div>
        <div class="row mt-2">
          <div class="col my-fs-lg-3 my-fs-2 fw-bold hiji-text-auto-observation">
            {{ 'user-actions.Auto-observation' | translate }}
          </div>
          <div class="col-auto">
            <span class="badge hiji-bg-auto-observation fw-bold rounded-circle">{{ repository.nbAutoObservation }}</span>
          </div>
          <div class="col text-end">
            <a
              class="btn btn-sm"
              [class.hiji-bg-manager]="!me"
              [class.hiji-bg-auto-observation]="me"
              id="btn-obs"
              [routerLink]="[
                '/coaching/observe',
                data.member.idUser,
                string_to_slug(data.member.firstName + '-' + data.member.lastName),
                campaign.idCampaign,
                string_to_slug(campaign.name | ms),
                repository.idRepository,
                string_to_slug(repository.name | ms)
              ]"
            >
              {{ me ? ("user-actions.S'auto observer" | translate) : ('user-actions.Observer' | translate) }}
            </a>
          </div>
        </div>
        <div class="row mt-2 mt-md-3">
          <div class="col my-fs-lg-3 my-fs-2 fw-bold hiji-text-manager">
            {{ 'user-actions.Observation' | translate }}
          </div>
          <div class="col-auto">
            <span class="badge hiji-bg-manager fw-bold rounded-circle">{{ repository.nbObservation }}</span>
          </div>
          <div class="col text-end">
            <a
              class="btn btn-sm btn-mine"
              [class.btn-secondary]="repository.nbObservation !== 0"
              [class.disabled]="!modulesDTO.coacheeAllowedToExchange && me"
              [class.d-none]="repository.nbObservation === 0"
              id="btn-exchange"
              [ngbTooltip]="
                repository.nbObservation === 0 && repository.nbAutoObservation !== 0
                  ? ('share-list.Pour démarrer un échange il faut que le manager ait observé son collaborateur' | translate)
                  : ''
              "
              [routerLink]="[
                '/coaching/share',
                data.member.idUser,
                string_to_slug(data.member.firstName + ' ' + data.member.lastName),
                repository.idRepository !== 0 ? 'new' : repository.idRepository
              ]"
            >
              {{ 'user-actions.Échanger' | translate }}
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
